<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="training-container">
    <div class="row ">
      <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
        <div class="d-flex">
          <mat-icon
            role="img"
            class="ml-1 m-0 mat-icon notranslate cursor-pointer material-icons mat-icon-no-color mr-2"
            style="color: #09305A;"
            aria-hidden="true"
            data-mat-icon-type="font"
            routerLink="/organisation/training"
            >arrow_back</mat-icon
          >
          <h2 class="text-cyan m-0" style="cursor:default" translate>
            {{ this.reopenDisabled ? 'Reopen Training' : 'Edit Training Campaign' }}
          </h2>
        </div>
        <div>
          <button
            class="btn-save ml-4 w-100"
            [disabled]="
              reopenDisabled
                ? buttonReopenDisabled
                : f?.title.invalid ||
                  f?.description.invalid ||
                  disable ||
                  optionsData.invalid ||
                  userListLoading ||
                  documentListLoader ||
                  (assignedUsersDisabled.length == 0 && selectedGroupDisabledData.length == 0)
            "
            [ngStyle]="{
              'background-color':
                reopenDisabled && buttonReopenDisabled
                  ? 'rgba(0,0,0,.26)'
                  : f?.title.invalid ||
                    f?.description.invalid ||
                    disable ||
                    optionsData.invalid ||
                    userListLoading ||
                    documentListLoader ||
                    (assignedUsersDisabled.length == 0 && selectedGroupDisabledData.length == 0)
                  ? 'rgba(0,0,0,.26)'
                  : '#4DB7C6'
            }"
            [ngClass]="{ 'btn-save': true }"
            (click)="submit()"
            translate
          >
            {{ this.reopenDisabled ? 'Reopen' : 'Edit_Campaign.Save' }}
          </button>
        </div>
      </div>
    </div>
    <div class="row scroll-view">
      <div class="col">
        <ng-container>
          <div class="row mt-1 mb-3" *ngIf="backend_error">
            <div class="col-12">
              <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
            </div>
          </div>
          <div *ngIf="error">
            <div class="row mt-2" *ngIf="form_error.title">
              <div class="col-12 col-sm-12 col-lg-12 ml-5">
                <div class="text-danger" translate>* {{ form_error.title[0] }}</div>
              </div>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error.error.title">
                <label style="font-size: 16px;" class="text-danger" translate>* {{ error.error.title[0] }}</label>
              </div>
            </div>
          </div>

          <form [formGroup]="campaignForm" novalidate *ngIf="campaignForm">
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="title" class="ml-3 pt-2 cb-label text-black-3" translate>Edit_Campaign.title1</label>
              </div>
              <div class="input-group col-9 col-sm-9 col-lg-9">
                <input
                  id="title"
                  class="form-control cb-input"
                  formControlName="title"
                  autocomplete="title"
                  (keyup)="onChangeForm($event)"
                  required
                  name="title"
                  type="text"
                  [maxLength]="256"
                />
              </div>
              <div class="col-12 error-mssg" *ngIf="(f?.title.touched || f?.title.dirty) && f?.title.errors">
                <label
                  style="margin-left: 19em;"
                  for="name"
                  class="text-danger"
                  *ngIf="f?.title.errors.required"
                  translate
                  >Edit_Campaign.*Title is required.</label
                >
                <label
                  for="name"
                  style="margin-left: 19em;"
                  class="text-danger"
                  *ngIf="f?.title.errors.maxLength"
                  translate
                  >Edit_Campaign.Title must be 255 characters or less</label
                >
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="description" class="ml-3 pt-2 cb-label text-black-3" translate
                  >Edit_Campaign.Description
                </label>
              </div>
              <div class="input-group col-9 col-sm-9 col-lg-9">
                <textarea
                  id="description"
                  class="form-control cb-input"
                  name="description"
                  type="text"
                  formControlName="description"
                  (keyup)="onChangeForm($event)"
                  cols="64"
                  rows="5"
                  [maxLength]="4097"
                ></textarea>
              </div>
              <div
                class="col-12 error-mssg"
                *ngIf="(f?.description.touched || f?.description.dirty) && f?.description.errors"
              >
                <label
                  style="margin-left: 19em;"
                  for="name"
                  class="text-danger"
                  *ngIf="f?.description.errors.required"
                  translate
                  >Document_Invite_Training_Groups.*Description is required.</label
                >
                <label
                  style="margin-left: 19em;"
                  for="name"
                  class="text-danger"
                  *ngIf="f?.description.errors.maxLength"
                  translate
                  >Document_Invite_Training_Groups.Description must be 4096 characters or less</label
                >
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="start_date" class="ml-3 pt-2 cb-label text-black-3" translate
                  >Edit_Campaign.Start_Date
                </label>
              </div>
              <div class="input-group col-3 col-sm-3 col-lg-3">
                <input
                  [disabled]="disableTextbox"
                  id="start_date"
                  class="form-control cb-input"
                  formControlName="start_date"
                  placeholder="yyyy-mm-dd"
                  name="start_date"
                  (dateSelect)="onChangeStartDate($event, 'change')"
                  (keyup)="onChangeForm($event)"
                  ngbDatepicker
                  #d1="ngbDatepicker"
                  (click)="d1.toggle()"
                  required
                  [minDate]="minDate"
                />
                <div class="input-group-append">
                  <button
                    [disabled]="reopenDisabled"
                    class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                    (click)="d1.toggle()"
                    type="button"
                  ></button>
                </div>
                <div class="col-12 error-mssg" *ngIf="(f?.start_date.touched || submitted) && f?.start_date.errors">
                  <label for="start_date" class="text-danger" *ngIf="f?.start_date.errors.required" translate>
                    Document_Invite_Training_Groups.*Start Date is required.</label
                  >

                  <label
                    for="start_date"
                    class="text-danger"
                    *ngIf="!f.start_date.errors.invalid && dateRangeValidator()"
                    translate
                  >
                    Document_Invite_Training_Groups.Start_date</label
                  >
                </div>
                <div class="col-12 error-mssg" *ngIf="submitted && f?.start_date.errors">
                  <label
                    for="start_date"
                    class="text-danger"
                    *ngIf="f?.start_date.errors.DateGreaterEqualToday_error && !disableTextbox"
                    translate
                  >
                    Document_Invite_Training_Groups.*Start Date must be today and greater than today.</label
                  >
                </div>
                <div *ngIf="error">
                  <div class="col-12 error-mssg" *ngIf="error.error.start_date">
                    <label class="text-danger" translate>* {{ error.error.start_date }}</label>
                  </div>
                </div>
              </div>
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="end_date" class="ml-5 pt-2 cb-label text-black-3" translate>Edit_Campaign.End_Date </label>
              </div>
              <div class="input-group col-3 col-sm-3 col-lg-3" style="margin-left: -2em;">
                <input
                  id="end_date"
                  class="form-control cb-input"
                  formControlName="end_date"
                  placeholder="yyyy-mm-dd"
                  name="end_date"
                  (dateSelect)="onChangeEndDate($event, 'change')"
                  (keyup)="onChangeForm($event)"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                  (click)="d2.toggle()"
                  [minDate]="minDate"
                  required
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                    (click)="d2.toggle()"
                    type="button"
                  ></button>
                </div>
                <div class="col-12 error-mssg" *ngIf="(f?.end_date.touched || submitted) && f?.end_date.errors">
                  <label for="end_date" class="text-danger" *ngIf="f?.end_date.errors.required" translate>
                    Document_Invite_Training_Groups.*End Date is required.</label
                  >
                  <label for="end_date" class="text-danger" *ngIf="f?.end_date.errors.DateTodayOrAfter_error" translate>
                    Document_Invite_Training_Groups.*End Date must be greater than today.</label
                  >
                  <label
                    for="end_date"
                    class="text-danger"
                    *ngIf="!f?.end_date.errors.invalid && dateRangeValidator()"
                    translate
                  >
                    Document_Invite_Training_Groups.End_date</label
                  >
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="groups" class="ml-3 pt-2 cb-label text-black-3" translate
                  >Edit_Campaign.Selected_Groups
                </label>
              </div>
              <div
                class="col-6 break-line"
                [ngClass]="{ 'disabled-events': actionReopen }"
                (click)="onGroupClick($event)"
              >
                <ng-multiselect-dropdown
                  class="multi-select-dropdown"
                  (keyup)="onChangeForm($event)"
                  [placeholder]="'Select trainee groups'"
                  [(ngModel)]="selectedGroupDisabledData"
                  [settings]="dropdownSettingsForGroup"
                  [data]="updatedGroupsList"
                  (onSelect)="onGroupSelect($event); updateUsersDisable($event)"
                  (onDeSelect)="onDeSelectTrainingGroup($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="reopenDisabled"
                >
                </ng-multiselect-dropdown>
              </div>
              <div>
                <i class="fa fa-spinner fa-spin refresh" *ngIf="gropuListLoader"></i>
              </div>
              <div *ngIf="form_error">
                <div class="col-12 error-mssg" *ngIf="form_error.training_groups">
                  <label class="text-danger" translate>* {{ form_error.training_groups[0] }}</label>
                </div>
              </div>
              <div
                *ngIf="
                  assignedUsersDisabled.length == 0 &&
                  selectedGroupDisabledData.length == 0 &&
                  !gropuListLoader &&
                  !userListLoading
                "
                class="col-12 error-mssg"
              >
                <label class="text-danger" style="margin-left: 14rem;" translate
                  >* At least one user or group should be selected.</label
                >
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="documents" class="ml-3 pt-2 cb-label text-black-3" translate>Users </label>
              </div>
              <div class="col-6 break-line">
                <ng-multiselect-dropdown
                  class="multi-select-dropdown"
                  (keyup)="onChangeForm($event)"
                  [placeholder]="'Select Users'"
                  [(ngModel)]="assignedUsersDisabled"
                  [settings]="dropdownSettingsForUsers"
                  [data]="unassignedUsers"
                  (onSelect)="onUserSelect($event)"
                  (onDeSelect)="onUserDeSelect($event)"
                  (change)="onUserChange($event)"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ng-multiselect-dropdown>
              </div>
              <div>
                <i class="fa fa-spinner fa-spin refresh" *ngIf="userListLoading"></i>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="groups" class="ml-3 pt-2 cb-label text-black-3" translate
                  >Document_Invite_Training_Groups.Documents_Groups
                </label>
              </div>
              <div class="col-6 break-line" [ngClass]="{ 'disabled-events': actionReopen }">
                <ng-multiselect-dropdown
                  class="multi-select-dropdown"
                  [placeholder]="'Select documents groups'"
                  [(ngModel)]="selectedDocumentGroupDisabled"
                  [settings]="dropdownSettingsForDocumentsGroups"
                  [data]="updateddocumentsGroupsList"
                  (ngModelChange)="updateDocumentsDisable($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="reopenDisabled"
                >
                </ng-multiselect-dropdown>
              </div>
              <div>
                <i class="fa fa-spinner fa-spin refresh" *ngIf="documentGroupListLoader"></i>
              </div>
              <div *ngIf="error">
                <div class="col-12 error-mssg" *ngIf="error.documents">
                  <label class="text-danger" translate>* {{ error.documents[0] }}</label>
                </div>
              </div>
              <div *ngIf="error">
                <div class="col-12 error-mssg" *ngIf="error[0]">
                  <label class="text-danger" translate>* {{ error[0] }}</label>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="documents" class="ml-3 pt-2 cb-label text-black-3" translate
                  >Edit_Campaign.Selected_Documents
                </label>
              </div>
              <!-- formControlName="selectedDocuments" -->
              <div class="col-6 break-line" [ngClass]="{ 'disabled-events': actionReopen }">
                <ng-multiselect-dropdown
                  class="document-multi-select"
                  class="multi-select-dropdown"
                  (keyup)="onChangeForm($event)"
                  [placeholder]="'Select documents'"
                  [(ngModel)]="selectedDocumentDisabledData"
                  [settings]="dropdownSettingsForDocuments"
                  [data]="updatedDocumentsList"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="reopenDisabled"
                >
                </ng-multiselect-dropdown>
              </div>
              <div>
                <i class="fa fa-spinner fa-spin refresh" *ngIf="documentListLoader"></i>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-2 col-sm-2 col-lg-2">
                <label for="external_links" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
                  >Edit_Campaign.External_documents
                  <i
                    class="fas fa-question-circle cb-tooltip-btn"
                    ngbTooltip="Add documents that are not managed by ComplianceBot."
                    triggers="hover:blur"
                    tooltipClass="cb-tooltip"
                    placement="bottom"
                  ></i
                ></label>
              </div>
              <div
                class="input-group col"
                formArrayName="external_links"
                *ngIf="optionsData.controls.length > 0; else no_content"
              >
                <div style="width: 100%; margin-top: 5px;" *ngFor="let opt of optionsData.controls; let i = index">
                  <div class="row" [formGroupName]="i" *ngIf="optionsData.controls.length > 0; else no_content">
                    <div class="col-2 d-flex-col">
                      <input
                        id="ext-doc-title"
                        (keyup)="onChangeForm($event)"
                        class="form-control cb-input"
                        placeholder="Document title"
                        formControlName="title"
                        name="title"
                        type="text"
                        (ngModelChange)="modelChange($event)"
                      />
                      <div
                        class="error-mssg"
                        *ngIf="(opt.get('title').touched || submitted) && opt.get('title').errors"
                      >
                        <label for="title" class="text-danger" *ngIf="opt.get('title').errors"
                          >*This field is required.</label
                        >
                      </div>
                    </div>

                    <div class="col-5 d-flex-col">
                      <input
                        id="url"
                        class="form-control cb-input"
                        placeholder="Document link."
                        (keyup)="onChangeForm($event)"
                        formControlName="url"
                        name="url"
                        type="text"
                        (ngModelChange)="urlChange($event)"
                      />
                      <div
                        class="error-mssg url-top-margin"
                        *ngIf="(opt.get('url').touched || submitted) && opt.get('url').errors"
                      >
                        <label for="url" class="text-danger" *ngIf="opt.get('url').errors.url"
                          >*{{ opt.get('url').errors.url.msg }}.</label
                        >
                      </div>
                      <div class="error-mssg url-top-margin" *ngIf="duplicateError && duplicateIndex === i">
                        <label for="url" class="text-danger">*Duplicate URL entered.</label>
                      </div>
                    </div>

                    <div class="col-1 delete-box">
                      <i
                        *ngIf="opt.status != 'DISABLED'"
                        (click)="removeOption(i)"
                        class="fa fa-trash fa fa-trash btn-edit cursor-pointer mt-2"
                        aria-hidden="true"
                        style="font-size: 17px;"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #no_content>
                <div class="col-9">
                  <button
                    for="groups"
                    class="pt-2 mt-1 text-cyan cursor-pointer"
                    (click)="addDocumentUrl()"
                    [disabled]="reopenDisabled"
                    [ngStyle]="{ color: reopenDisabled ? 'rgba(0,0,0,.26)' : '#4db7c6' }"
                    translate
                    style="font-size: 14px;background-color: transparent !important;"
                  >
                    Edit_Campaign.Add_New_External_Document
                  </button>
                </div>
              </ng-template>
            </div>
            <div class="row mt-3" *ngIf="optionsData.controls.length > 0">
              <div class="col-2"></div>
              <div class="col-9 justify-content-between d-flex">
                <button
                  style="background-color: transparent;"
                  for="groups"
                  class="ml-1 text-cyan add_document_url cursor-pointer"
                  (click)="addDocumentUrl()"
                  [disabled]="reopenDisabled ? reopenDisabled : disabledAddNew"
                  [ngStyle]="{
                    color: reopenDisabled ? 'rgba(0,0,0,.26)' : disabledAddNew ? 'rgba(0,0,0,.26)' : '#4db7c6'
                  }"
                  translate
                >
                  Document_Invite_Training_Groups.Add_External_Document
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6 col-sm-12 col-lg-6">
                <div class="input-group pt-2 mb-2">
                  <label for="Category" for="renewal_recurring" class="ml-3 mt-1 cb-label text-black-3" translate
                    >Create_Document.Recurring_renewal</label
                  >
                  <mat-checkbox
                    class="mt-1"
                    formControlName="renewal_recurring"
                    id="renewal_recurring"
                    name="renewal_recurring"
                    style="margin-left: 10em;"
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    #checkBox
                    [(ngModel)]="renewalRecurring"
                  ></mat-checkbox>
                </div>
              </div>
            </div>

            <div *ngIf="checkBox.checked">
              <div class="row">
                <div class="col-6 col-sm-12 col-lg-6">
                  <span class="text-black-3 ml-3 mt-2 cb-label" translate>Create_Document.Sel_Rec</span>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Select_Time</label
                  >
                </div>
                <div class="col-6 col-sm-6 col-lg-6">
                  <div class="cb-input cb-time">
                    <i class="fa fa-clock"></i>
                    <input
                      [disabled]="reopenDisabled"
                      (change)="changeRecurrence($event)"
                      placeholder="{{ this.time }}"
                      aria-label="Time"
                      class="time_input"
                      [ngxTimepicker]="darkPicker"
                      [value]="data.time"
                      readonly
                    />
                    <ngx-material-timepicker
                      class="timepicker"
                      #darkPicker
                      [ngxMaterialTimepickerTheme]="darkTheme"
                      (timeSet)="timeSet($event)"
                    ></ngx-material-timepicker>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Select_Schedule
                  </label>
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    class="form-control cb-input"
                    [(ngModel)]="data.renewal_schedule"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="dataRefresh($event.target.value)"
                  >
                    <option *ngFor="let schedule of schedules" [value]="schedule.value">
                      {{ schedule.display_value }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Allowed_duration</label>
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    class="form-control cb-input"
                    [(ngModel)]="allowed_duration_selected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="changeRecurrence($event)"
                  >
                    <option value="1" *ngFor="let duration of allowed_duration" [value]="duration" translate>{{
                      duration
                    }}</option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'WEEKLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="changeRecurrence($event)"
                  >
                    <option *ngFor="let day of days" [value]="day.value">
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-WEEKLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_bi" [value]="week.value">
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>
                    Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value">
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value">
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-MONTHLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.month"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let month of numbered_bi" [value]="month.value">
                      {{ month.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value">
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value" translate>
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'QUARTERLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.month"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let month of numbered_quarter" [value]="month.value" translate>
                      {{ month.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value" translate>
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'SEMI-ANNUALLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.month"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let months of months_half_year" [value]="months.value" translate>
                      {{ months.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value" translate>
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'ANNUALLY'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.month"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let months of months_year" [value]="months.value" translate>
                      {{ months.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value" translate>
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row mt-3" *ngIf="data.renewal_schedule === 'EVERY TWO YEARS'">
                <div class="col-4 col-sm-6 col-lg-4">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Year</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.year"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let year of numbered_bi" [value]="year.value" translate>
                      {{ year.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Month</label
                  >
                </div>
                <div class="input-groupcol-5 col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.month"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let months of months_year" [value]="months.value" translate>
                      {{ months.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Week_Month</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                      {{ week.display_string }}
                    </option>
                  </select>
                </div>
                <div class="col-4 col-sm-6 col-lg-4 mt-3">
                  <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                    >Create_Document.Day</label
                  >
                </div>
                <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                  <select
                    [disabled]="reopenDisabled"
                    (change)="changeRecurrence($event)"
                    class="form-control cb-input"
                    [(ngModel)]="data.day_of_week"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option *ngFor="let day of days" [value]="day.value" translate>
                      {{ day.display_string }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
