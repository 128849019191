import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents/documents.service';
import { ChartService } from '../chart.service';
import _ from 'lodash';
import { TestCloudServiceProviderComponent } from './test-cloud-service-provider/test-cloud-service-provider.component';
import { ResourcesComponent } from './resources/resources.component';
import { HttpClient } from '@angular/common/http';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-cloud-service-provider',
  templateUrl: './cloud-service-provider.component.html',
  styleUrls: ['./cloud-service-provider.component.scss']
})
export class CloudServiceProviderComponent implements OnInit, OnDestroy {
  @ViewChild('frameworkSelect', { static: false }) frameworkSelect: MatSelect;
  @ViewChild('connectorSelect', { static: false }) connectorSelect: MatSelect;
  @ViewChild('accountSelect', { static: false }) accountSelect: MatSelect;
  @ViewChild('serviceSelect', { static: false }) serviceSelect: MatSelect;

  @ViewChild(TestCloudServiceProviderComponent) testCloudComponent!: TestCloudServiceProviderComponent;
  @ViewChild(ResourcesComponent) resourcesComponent!: ResourcesComponent;
  activeTab: string = 'all-status';
  OrganisationID: string | null;
  orgGuidSubscription: any;
  frameworks: any[];
  connectors: any[];
  accounts: any[];
  resources: any[];
  services: any[];
  selectedTab: string = 'Dashboard';
  selectedFrameworks: any[];
  selectedConnectors: any[];
  selectedAccounts: any[];
  selectedResources: any[];
  selectedServices: any[];
  includeIgnoredTests: boolean = false;
  loading: boolean = false;
  isDataAvailable: boolean = false;
  filters: any = {};
  activeStatus: string = '';
  statuses: any;
  daysSelected = 7;
  orgName: any;
  pdfDownloading = false;
  latestRunDate = '';
  isTreeListEmpty: boolean = false;
  isResourceListEmpty: boolean = false;
  days = [
    { value: 7, title: 'Last 7 days' },
    { value: 15, title: 'Last 15 days' },
    { value: 30, title: 'Last 30 days' }
  ];
  tabs: any[] = [
    {
      title: 'Dashboard',
      active: true
    },
    {
      title: 'Tests',
      active: false
    },
    {
      title: 'Resources',
      active: false
    }
  ];
  tabsData: any = [
    {
      title: 'COMPLIANT',
      code: 'compliant',
      childStatus: 'compliant',
      total: 0,
      childCode: 'status-compliant',
      dot: 'compliant-dot',
      active: false,
      show: true
    },
    {
      title: 'DANGER',
      code: 'danger',
      childStatus: 'danger',
      total: 0,
      childCode: 'status-danger',
      dot: 'danger-dot',
      active: false,
      show: true
    },
    {
      title: 'WARNING',
      code: 'warning',
      childStatus: 'warning',
      dot: 'warning-dot',
      childCode: 'status-warning',
      total: 0,
      active: false,
      show: true
    },
    {
      title: 'IGNORED',
      code: 'ignored',
      childStatus: 'ignored',
      dot: 'ignored-dot',
      childCode: 'status-ignored',
      total: 0,
      active: false,
      show: false
    },
    {
      title: 'NOT STARTED',
      code: 'not_started',
      childStatus: 'not-started',
      childCode: 'status-not-start',
      total: 0,
      active: false,
      dot: 'not-started-dot',
      show: true
    }
  ];
  statusCount = {
    danger: 0,
    warning: 0,
    compliant: 0,
    ignored: 0,
    not_started: 0,
    total: 0
  };
  testSummary = {
    total_tests_sum: 0,
    failed_tests_sum: 0,
    error_tests_sum: 0,
    passed_tests_sum: 0,
    passed_percentage: 0,
    ignored_tests_sum: 0
  };
  resourceSummary = {
    total_resources_sum: 0,
    failed_resources_sum: 0,
    passed_resources_sum: 0,
    compliant_percentage: 0
  };
  testPage = 1;
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private chartService: ChartService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this.orgGuidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.testTab == 'true') {
        this.changeTab('Tests');
        this.testPage = params.page;
        this.includeIgnoredTests = params.isTestIgnored == 'false' ? false : true;
        this.activeStatus = params.status;

        if (this.activeStatus == 'not_started') {
          this.activeTab = 'status-not-started';
        } else {
          this.activeTab = this.tabsData.find((item: any) => item.code == params.status).childCode;
        }
      }
    });

    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getAllFrameworks();
      this.getAllConnectors();
      this.getAllAccounts();
      this.getAllResouces();
      this.getAllServices();
      if (this.selectedTab === 'Dashboard') {
        this.getDashboardCharts();
        this.getHistoryChartData();
      }
    });
  }
  handleTreeListStatus(status: boolean) {
    this.isTreeListEmpty = !status;
  }
  handResourcesListStatus(status: boolean) {
    this.isResourceListEmpty = !status;
  }
  isExportButtonDisabled(): boolean {
    if (this.selectedTab === 'Tests' && this.isTreeListEmpty) {
      return true;
    } else if (this.selectedTab === 'Resources' && this.isResourceListEmpty) {
      return true;
    }
    return false;
  }
  onExportButtonClick() {
    if (this.selectedTab == 'Tests') {
      this.testCloudComponent.exportTableData();
    }
    if (this.selectedTab == 'Resources') {
      this.resourcesComponent.exportTableData();
    }
  }
  downloadCSV(csvData: string) {
    console.log(csvData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = this.selectedTab == 'Tests' ? 'Cloud-Test.csv' : 'Cloud-resources.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  createChartWithEmptyCheck(
    chartId: string,
    chartType: any,
    labels: string[],
    data: number[],
    backgroundColor: string[],
    titleText: string
  ) {
    const isDataEmpty = !data || data.length === 0 || data.every(value => value === 0);
    if (isDataEmpty) {
      labels = ['No Data'];
      data = [1];
      backgroundColor = ['#e1e6eb'];
    }
    this.chartService.createChart(chartId, chartType, labels, data, backgroundColor, titleText);
  }
  getDashboardCharts() {
    const queryString = this.objectToQueryString(this.filters);
    this.documentsService
      .getDashboardCharts(this.OrganisationID, queryString, this.includeIgnoredTests)
      .subscribe((res: any) => {
        this.latestRunDate = res['latest_run_date'];
        this.statusCount = res['summary_data']['status_count'];
        this.statusCount['total'] = this.statusCount['warning'] + this.statusCount['danger'];
        this.testSummary = res['summary_data']['test_summary'];
        this.resourceSummary = res['summary_data']['resource_summary'];
        if (this.includeIgnoredTests) {
          this.createChartWithEmptyCheck(
            'testsummarychart',
            'doughnut',
            ['Passed', 'Failed', 'Ignored', 'Error'],
            [
              this.testSummary.passed_tests_sum || 0,
              this.testSummary.failed_tests_sum || 0,
              this.statusCount.ignored || 0,
              this.testSummary.error_tests_sum || 0
            ],
            ['#0d9647', '#f16163', '#7C83EB', '#4D4D4D'],
            'My Doughnut Chart'
          );
        } else {
          this.createChartWithEmptyCheck(
            'testsummarychart',
            'doughnut',
            ['Passed', 'Failed', 'Error'],
            [
              this.testSummary.passed_tests_sum || 0,
              this.testSummary.failed_tests_sum || 0,
              this.testSummary.error_tests_sum || 0
            ],
            ['#0d9647', '#f16163', '#4D4D4D'],
            'My Doughnut Chart'
          );
        }

        this.createChartWithEmptyCheck(
          'totalresourcechart',
          'doughnut',
          ['Passed', 'Failed'],
          [this.resourceSummary.passed_resources_sum || 0, this.resourceSummary.failed_resources_sum || 0],
          ['#0d9647', '#f16163'],
          'My Doughnut Chart'
        );

        this.chartService.createHorizontalBarChart(
          'failurechart',
          ['CRITICAL', 'WARNING'],
          [this.statusCount.danger || 0, this.statusCount.warning || 0],
          ['#ea4335', '#fbbc04'],
          'My Doughnut Chart'
        );
        const parsedPrograms = JSON.parse(localStorage.getItem('compliance_programs'));
        this.orgName = parsedPrograms[0]['organization']['name'];
      });
  }
  getHistoryChartData() {
    const queryString = this.objectToQueryString(this.filters);
    this.documentsService
      .getHistoryChartData(this.OrganisationID, queryString, this.daysSelected, this.includeIgnoredTests)
      .subscribe((res: any) => {
        const chartData = this.prepareChartData(res.history_data);
        this.chartService.createLineChart('testhistory', chartData.dates, chartData.datasets, 'Status Count History');
      });
  }
  objectToQueryString(obj: Record<string, unknown>): string {
    return Object.entries(obj)
      .filter(([_, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map(item => encodeURIComponent(String(item)));
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(encodedValues))}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
  }

  prepareChartData(inputData: any) {
    const dates = inputData.map((item: any) => {
      const date = new Date(item.date);
      return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) + this.ordinalSuffix(date.getDate());
    });

    const dangerData = inputData.map((item: any) => item.status_counts.danger);
    const warningData = inputData.map((item: any) => item.status_counts.warning);

    const datasets = [
      {
        data: dangerData,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: '#db3546',
        label: 'Danger'
      },
      {
        data: warningData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: '#fbbc04',
        label: 'Warning'
      }
    ];

    return {
      dates,
      datasets
    };
  }

  ordinalSuffix(day: any) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  onDaysChange() {
    this.getHistoryChartData();
  }
  getAllFrameworks() {
    this.documentsService.getAllFrameworks(this.OrganisationID).subscribe(res => {
      this.frameworks = res;
      this.documentsService.selectedFrameworks$.next(this.frameworks);
    });
  }
  changeActiveTab(tab: any = {}) {
    this.tabsData.forEach((item: any) => {
      item['active'] = item['code'] === tab.code ? true : false;
    });
    this.changeTab('Tests', true, tab);
  }
  getAllConnectors() {
    this.documentsService.getAllConnectors(this.OrganisationID).subscribe(res => {
      this.connectors = res;
      this.documentsService.selectedConnectors$.next(this.connectors);
    });
  }

  getAllAccounts() {
    this.documentsService.getAllAccounts(this.OrganisationID).subscribe(res => {
      this.accounts = res;
      this.documentsService.selectedAccounts$.next(this.accounts);
    });
  }

  getAllResouces() {
    this.documentsService.getAllResouces(this.OrganisationID).subscribe(res => {
      this.resources = res;
    });
  }

  getAllServices() {
    this.documentsService.getAllServices(this.OrganisationID).subscribe(res => {
      this.services = res;
    });
  }
  getAccountsByConnector() {
    this.selectedAccounts = [];
    this.documentsService.getAccountsByConnector(this.OrganisationID, this.selectedConnectors).subscribe(res => {
      this.accounts = res;
    });
  }
  getservicesByConnectorAndAccounts() {
    this.selectedServices = [];
    this.documentsService
      .getservicesByConnectorAndAccounts(this.OrganisationID, this.selectedConnectors, this.selectedAccounts)
      .subscribe(res => {
        this.services = res;
      });
  }

  onFrameworksChange(framework: any) {
    this.documentsService.selectedFrameworks$.next(this.selectedFrameworks);
    this.filters['frameworks'] = this.selectedFrameworks;
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }
  getFrameworkNames(): string {
    return this.selectedFrameworks
      .map(id => this.getFramework(id))
      .filter(name => name !== undefined) // Filter out undefined names if any
      .join(', ');
  }
  onConnectorChange(connector: any) {
    this.documentsService.selectedConnectors$.next(this.selectedConnectors);
    this.filters['connectors'] = this.selectedConnectors;
    this.filters['accounts'] = [];
    this.filters['services'] = [];
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
    this.getAccountsByConnector();
    this.getservicesByConnectorAndAccounts();
  }

  onAccountChange(account: any) {
    this.documentsService.selectedAccounts$.next(this.selectedAccounts);
    this.filters['accounts'] = this.selectedAccounts;
    this.filters['services'] = [];
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
    this.getservicesByConnectorAndAccounts();
  }

  onResourceChange(resource: any) {
    this.documentsService.selectedResources$.next(this.selectedResources);
    this.filters['resources'] = this.selectedResources;
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }

  onServicesChange(service: any) {
    this.documentsService.selectedServices$.next(this.selectedServices);
    this.filters['services'] = this.selectedServices;
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }

  getFramework(id: any) {
    let framework = this.frameworks.find((framework: any) => framework.guid == id);
    return framework.name;
  }

  getConnector(key: any) {
    let connector = this.connectors.find((connector: any) => connector.key == key);
    return connector.value;
  }

  getAccount(id: string) {
    let account = this.accounts.find((account: any) => account.guid == id);
    return account.name;
  }

  removeFrameworks() {
    delete this.filters['frameworks'];
    this.activeStatus = '';
    this.activeTab = 'all-status';
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }

  removeConnectors() {
    delete this.filters['connectors'];
    this.activeStatus = '';
    this.activeTab = 'all-status';
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
    this.getAllAccounts();
    this.getAllServices();
  }

  removeAccounts() {
    delete this.filters['accounts'];
    this.activeStatus = '';
    this.activeTab = 'all-status';
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
    this.getAllServices();
  }

  removeResource() {
    delete this.filters['resources'];
    this.activeStatus = '';
    this.activeTab = 'all-status';
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }
  openMatSelect(select: string) {
    if (select == 'connectorSelect') {
      this.connectorSelect.open();
    }
    if (select == 'frameworkSelect') {
      this.frameworkSelect.open();
    }
    if (select == 'accountSelect') {
      this.accountSelect.open();
    }
    if (select == 'serviceSelect') {
      this.serviceSelect.open();
    }
  }
  removeServices() {
    delete this.filters['services'];
    this.activeStatus = '';
    this.activeTab = 'all-status';
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
  }

  changeTab(title: string, changeActiveTab: boolean = false, tab: any = {}) {
    if (title !== 'Tests') {
      this.testPage = 1;
    } // we are doing this because if url params are having some value then on change tab we should reset the page value so it doesnt stay retained.
    this.statuses = null;
    this.activeTab = 'all-status';
    this.activeStatus = '';
    if (changeActiveTab) {
      if (tab.code == 'not_started') {
        this.activeTab = 'status-not-started';
        this.activeStatus = 'not_started';
      } else {
        this.activeTab = this.tabsData.find((item: any) => item.code == tab.code).childCode;
        this.activeStatus = this.tabsData.find((item: any) => item.code == tab.code).childStatus;
      }
    }
    this.tabs = this.tabs.map(tab => ({
      ...tab,
      active: tab.title === title
    }));

    this.selectedTab = title;

    switch (title) {
      case 'Dashboard':
        this.getDashboardCharts();
        this.getHistoryChartData();
        this.selectedResources = [];
        // this.router.navigate(['organisation/cloud']);
        break;
      case 'Tests':
        this.selectedResources = [];
        // this.router.navigate(['organisation/cloud/test-cloud-service-provider']);
        break;
      case 'Resources':
        this.selectedResources = [];
        // this.router.navigate(['organisation/cloud/resources']);
        break;
      default:
        break;
    }
  }

  setStatus(status: string) {
    this.activeStatus = status;
  }
  setStatuses(res: any) {
    this.statuses = res;
  }

  getFilteredKeys(): string[] {
    return Object.keys(this.filters).filter(key => Array.isArray(this.filters[key]) && this.filters[key].length > 0);
  }
  getSelectedDayTitle(): string {
    const selected = this.days.find(day => day.value === this.daysSelected);
    return selected ? selected.title : '';
  }

  async downloadDashboard() {
    this.pdfDownloading = true;
    this.cdr.detectChanges();
    await this.waitForDomUpdate();
    try {
      await this.orgService.generatePdfWithOnlineImages(
        ['pdf-organization-content', 'chips-container', 'cloudDashboard'],
        'cloud-dashboard'
      );
      this.showStaticTextForPdf(false);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      this.pdfDownloading = false;
      this.cdr.detectChanges();
    }
  }

  showStaticTextForPdf(isPdfDownloading: boolean) {
    const staticTextElement = document.querySelector('.static-text');
    const staticTextsElement = document.querySelector('.static-texts');
    const dynamicSelectElement = document.querySelector('.dynamic-select');

    if (isPdfDownloading) {
      this.renderer.setStyle(staticTextElement, 'display', 'block');
      this.renderer.setStyle(staticTextsElement, 'display', 'block');
      this.renderer.setStyle(dynamicSelectElement, 'display', 'none');
    } else {
      this.renderer.setStyle(staticTextElement, 'display', 'none');
      this.renderer.setStyle(dynamicSelectElement, 'display', 'block');
    }
  }

  waitForDomUpdate(): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, 100)); // Waits for 100ms to let Angular update the view
  }

  setResourceTitle(title: string) {
    this.changeTab('Tests');
    this.selectedResources = [title];
  }

  clearFilters() {
    this.filters['frameworks'] = [];
    this.filters['connectors'] = [];
    this.filters['accounts'] = [];
    this.filters['services'] = [];
    this.selectedFrameworks = [];
    this.selectedConnectors = [];
    this.selectedAccounts = [];
    this.selectedServices = [];
    if (this.selectedTab === 'Dashboard') {
      this.getDashboardCharts();
      this.getHistoryChartData();
    }
    this.getAllAccounts();
    this.getAllResouces();
    this.getAllServices();
  }
}
