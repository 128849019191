import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

const log = new Logger('ErrorHandlerInterceptor');

let four_hundred_errors = [
  401,
  402,
  405,
  406,
  407,
  408,
  410,
  411,
  412,
  413,
  414,
  415,
  416,
  417,
  418,
  421,
  422,
  423,
  424,
  425,
  426,
  428,
  429,
  431,
  451
];

let five_hundred_errors = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private translate: TranslateService, private toaster: ToasterService) {}

  private readonly logoPartialUrl = '/login';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }
  // constructor(private authenticationService: AuthenticationService) {}

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any> | any): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    if (response?.error?.error === 'session_timeout') {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/login?error=session_timeout';
    }

    let result: any;
    if (four_hundred_errors.includes(response['status']) || five_hundred_errors.includes(response['status'])) {
      this.translate.get('alert_message.alert_msg').subscribe(res => {
        this.toaster.showError(res);
      });
    } else if (response['error'] && response['status'] !== 400) {
      for (var key in response['error']) {
        if (typeof response['error'][key] === 'object') {
          result = response['error'][key][0];
        } else if (typeof response['error'][key] === 'string' && response['error'][key] !== 'Invalid page.') {
          result = response['error'][key];
        }
      }
      if (result) {
        this.translate.get(result).subscribe(res => {
          this.toaster.showError(res);
        });
      }
    }
    throw response;
  }
}
