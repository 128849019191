import { SidenavService } from '@app/core/modal/sidenav.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentsService } from '../documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '@app/core/user.service';
import { CreateFolderComponent } from '../create-folder/create-folder.component';
import { CreateDocumentComponent } from '../create-document/create-document.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/settings/settings.service';
import { MatMenuTrigger } from '@angular/material/menu';
interface FolderItem {
  title: string;
  children?: FolderItem[];
  guid: string;
}

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  @ViewChild('menuTrigger') filterTrigger: MatMenuTrigger;
  OrganisationID: string;
  folderData: any[] = [];
  toggledNodes: Set<string> = new Set();
  guidSubscription: Subscription;
  selectedDocFolderPathsub: Subscription;
  page: number = 1;
  treeApiList: any;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  scrollLoader = false;
  pagnationData: any;
  paginatedDataUrl: any;
  error: any;
  totalLength: any;
  totalPages: any;
  currentPage: number = 1;
  pageSize: number = 10;
  handleClickevt: boolean = false;

  searchDocumentTitle: any = '';
  templateFilter: string = '';
  monthFilter: string = '';
  yearFilter: string = '';
  workspaceTemplateType: any = '';
  searchFolder = '';
  searchText: string = '';
  folderDocumentListGuid: string;
  folderListpageNumber: number = 1;
  loading: boolean = false;
  selectedFolderName: string[];
  selectedParentFolderName: string = '';
  selectedFolderPath: string = '';
  folderPath: any[] = [];
  showBreadcrumbs: boolean = false;
  isSideNavOpen: boolean = false;
  goToPage: number = 1;
  pages: number = 1;
  authorSearchText = '';
  selecteddocFolderpathData: any;
  pageSizes = [
    { value: 10, label: '10 / page' },
    { value: 25, label: '25 / page' },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' }
  ];
  private nameColors = new Map<string, string>();
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private router: Router,
    private userservice: UserService,
    private sidenavService: SidenavService,
    private toasterService: ToasterService,
    private translate: TranslateService,
    private settingsService: SettingsService
  ) {
    this.toggledNodes = new Set();
  }
  document_types: any = [];
  peopleList: any = [];
  selectedFilter = 'Type';
  customFilters: any = {};
  customFilterString = '';
  filtersSelectedCount = 0;
  openFolderGuid: string = '';
  openFolder: any;
  filtersMenu: any = [
    {
      title: 'Type',
      active: true,
      show: true
    },
    {
      title: 'Author',
      active: false,
      show: true
    }
  ];
  setFilter(filter: any = {}) {
    this.filtersMenu.forEach((item: any) => {
      item['active'] = item['title'] === filter.title ? true : false;
    });
    this.selectedFilter = filter.title;
  }
  ngOnInit(): void {
    this.toggledNodes.clear();
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      if (this.OrganisationID) {
        this.getFolderList();
        this.getPeopleList();
        this.getDocumentTypes();
      }
    });
  }
  getDocumentTypes() {
    this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
      this.document_types = data;
    });
  }
  getPeopleList(): void {
    this.settingsService.getOrganizationPeopleList(this.OrganisationID).subscribe(
      (data: any) => {
        this.peopleList = data.data;
        this.peopleList.forEach((item: any) => {
          if (item.guid == this.userservice._userDetails.user['org_user_guid']) {
            item['last_name'] = item['last_name'] + ' (You)';
          }
        });
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }
  noFilterAction() {
    this.authorSearchText = '';
    const updateSelection = (list: any, filterKey: any, selectionKey: any, compareKey = 'guid') => {
      list?.forEach((item: any) => {
        item[selectionKey] = this.customFilters[filterKey]?.includes(item[compareKey]) || false;
      });
    };
    updateSelection(this.peopleList, 'author', 'authorSelected');
    updateSelection(this.document_types, 'document_types', 'typeSelected', 'slug');
  }
  clickAndScrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.click();
      element.classList.add('clicked');
      setTimeout(() => {
        element.classList.remove('clicked');
      }, 500);
    }
  }

  applyCustomFilters() {
    this.authorSearchText = '';
    let filters = {};
    filters['document_types'] = this.document_types
      .filter((item: any) => item['typeSelected'])
      .map((item: any) => item['slug']);
    filters['author'] = this.peopleList.filter((item: any) => item['authorSelected']).map((item: any) => item['guid']);
    this.customFilters = JSON.parse(JSON.stringify(filters));
    this.filtersSelectedCount = Object.values(this.customFilters).filter((arr: any = []) => arr.length > 0).length;
    const queryString = this.objectToQueryString(filters);
    this.customFilterString = queryString;
    this.page = 1;
    // this.getFolderList();
    this.getFolderElementList(this.openFolderGuid ? this.openFolderGuid : this.folderData[0]['guid']);
  }
  objectToQueryString(obj: Record<string, unknown>): string {
    return Object.entries(obj)
      .filter(([_, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map(item => encodeURIComponent(String(item)));
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(encodedValues))}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
  }
  resetFilterNoAPiCall() {
    this.document_types?.forEach((item: any) => (item['typeSelected'] = false));
    this.peopleList?.forEach((item: any) => (item['authorSelected'] = false));
    this.customFilters = {};
    this.filtersSelectedCount = Object.values(this.customFilters).filter((arr: any = []) => arr.length > 0).length;
  }
  private getFolderList() {
    this.loading = true;
    let firstFolderData: any = null;
    let folderPathIndex = 0; // Track the index of the current GUID in folderPath
    this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(data => {
      this.folderData = data.map((item: any) => ({
        ...item,
        isMainFolder: true
      }));
      this.selectedDocFolderPathsub = this.documentsService.selectedDocFolderPath.subscribe(res => {
        this.selecteddocFolderpathData = res;
        if (this.selecteddocFolderpathData && this.selecteddocFolderpathData.folderPath.length > 0) {
          const firstGuid = this.selecteddocFolderpathData.folderPath[folderPathIndex].guid;
          firstFolderData = this.folderData.find((folder: any) => folder.guid === firstGuid);

          if (firstFolderData) {
            this.processSubFoldersRecursively(firstFolderData, folderPathIndex + 1);
            this.handleFolderClick(firstFolderData, false);
          }
        }
      });

      if (this.folderData.length > 0) {
        this.openFolderGuid = this.openFolderGuid ? this.openFolderGuid : this.folderData[0]['guid'];
        this.getSubFolderList(
          this.openFolder ? this.openFolder : firstFolderData ? firstFolderData : this.folderData[0]
        );
        this.getFolderElementList(this.openFolderGuid ? this.openFolderGuid : this.folderData[0]['guid']);
        this.selectedParentFolderName = this.openFolder ? this.openFolder['title'] : this.folderData[0]['title'];
        this.selectedFolderName = this.openFolder ? this.openFolder : this.folderData[0];
      }
      this.loading = false;
    });
  }

  processSubFoldersRecursively(folderData: any, nextIndex: number): void {
    this.getSubFolderList(folderData)
      .then(subFolderData => {
        if (
          subFolderData &&
          this.selecteddocFolderpathData &&
          nextIndex < this.selecteddocFolderpathData.folderPath.length
        ) {
          const nextGuid = this.selecteddocFolderpathData.folderPath[nextIndex].guid;
          const nextFolderData = subFolderData.find((subFolder: any) => subFolder.guid === nextGuid);
          if (nextFolderData) {
            if (nextFolderData.guid === this.selecteddocFolderpathData.selectedGuid) {
              this.getFolderElementList(this.selecteddocFolderpathData.selectedGuid);
              this.handleFolderClick(nextFolderData, false);
              this.getSubFolderList(nextFolderData).then(finalData => {});
            } else {
              this.processSubFoldersRecursively(nextFolderData, nextIndex + 1);
            }
          }
        }
      })
      .catch(error => {
        console.error('Error processing subfolders:', error);
      });
  }

  clearFilters() {
    this.resetFilterNoAPiCall(); // clear
    this.applyCustomFilters();
  }
  changePageSize(pageSize: any) {
    this.pageSize = pageSize;
    this.getFolderElementList(this.openFolderGuid);
  }
  pageChange(value: any) {
    this.page = value;
    this.getFolderElementList(this.openFolderGuid);
  }
  goToPageChange(value: number) {
    this.page = value;
    this.getFolderElementList(this.openFolderGuid);
  }
  get checkCreateAccess() {
    return this.userservice._userDetails.user['role'].includes('document_author');
  }
  get createFolderAccess() {
    return (
      this.userservice._userDetails.user['role'].includes('document_author') ||
      this.userservice._userDetails.user['role'].includes('organization_admin_role')
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollData() {
    this.scrollLoader = true;
    this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
      data => {
        this.scrollLoader = false;
        this.folderData = data;
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScrollUp() {
    this.scrollLoader = false;
  }
  getSubFolderList(folder: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.toggledNodes.has(folder.guid)) {
        if (this.handleClickevt) {
          this.toggledNodes.delete(folder.guid);
        }
        resolve(null);
      } else {
        this.toggledNodes.add(folder.guid);

        if (!folder.children) {
          this.documentsService.getOrganizationSubFolderList(this.OrganisationID, folder.guid).subscribe(
            children => {
              folder.children = children;
              resolve(children); // Resolve with fetched children
            },
            error => {
              console.error('Error fetching subfolders:', error);
              reject(error); // Reject promise on error
            }
          );
        } else {
          resolve(folder.children);
        }
      }
    });
  }
  getFilteredKeys(): string[] {
    return Object.keys(this.customFilters).filter(
      key => Array.isArray(this.customFilters[key]) && this.customFilters[key].length > 0
    );
  }
  get selectedAuthor() {
    let authors = this.peopleList
      .filter((item: any) => this.customFilters?.author?.includes(item.guid) && item.authorSelected)
      .map((item: any) => item.first_name + item.last_name);
    return authors;
  }
  get selectedType() {
    let types = this.document_types
      .filter((item: any) => this.customFilters?.document_types?.includes(item.slug) && item.typeSelected)
      .map((item: any) => item.value);

    return types;
  }
  removeFilter(filter: string = '') {
    let filterOptionMapper = {
      author: 'authorSelected'
    };
    if (filter === 'author') {
      this.peopleList.forEach((item: any) => (item[filterOptionMapper[filter]] = false));
    }
    if (filter === 'type') {
      this.document_types.forEach((item: any) => (item['typeSelected'] = false));
    }
    this.applyCustomFilters();
  }
  setFolder(title: string) {
    let folder: FolderItem | null = this.findFolder(this.folderData, title);
    this.handleFolderClick(folder);
  }
  findFolder(data: FolderItem[], targetTitle: string): FolderItem | null {
    for (const item of data) {
      if (item?.guid === targetTitle) {
        return item;
      }

      if (item.children && item.children.length > 0) {
        const result = this.findFolder(item.children, targetTitle);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  handleFolderClick(folder: any, isScroll = true, evt?: any): void {
    if (evt?.type == 'click') {
      this.handleClickevt = true;
    }
    this.page = 1;
    this.selectedFolderName = folder;
    const folderPath = this.findFolderPath(this.folderData, folder.guid);
    if (folderPath) {
      this.folderPath = folderPath;
      this.selectedParentFolderName = folder.title;
    }
    if (!folder.parent_folder) {
      this.folderPath = [folder];
    } else {
      const parentFolder = this.getFolderByGuid(folder.parent_folder);
      if (parentFolder) {
        this.folderPath = this.updateFolderPath(parentFolder, folder);
      } else {
        this.folderPath = [folder];
      }
    }
    this.selectedFolderPath = this.getBreadcrumbString();
    this.showBreadcrumbs = this.selectedFolderPath.includes('>');
    this.getSubFolderList(folder);
    this.getFolderElementList(folder.guid);
    this.openFolderGuid = folder.guid;
    this.openFolder = folder;
    if (isScroll) {
      this.openParentFolders(folder);
    }
  }
  openParentFolders(folder: any): void {
    if (folder.parent_folder) {
      this.toggledNodes.add(folder.parent_folder);
      const parentFolder = this.getFolderByGuid(folder.parent_folder);
      if (parentFolder) {
        this.openParentFolders(parentFolder);
      }
    }
    setTimeout(() => {
      this.clickAndScrollToElement(folder.guid);
    }, 500);
  }
  isToggled(folder: any): boolean {
    return this.toggledNodes.has(folder.guid);
  }
  updateFolderPath(parentFolder: any, folder: any): any[] {
    let path = [parentFolder];
    while (parentFolder.parent_folder) {
      parentFolder = this.getFolderByGuid(parentFolder.parent_folder);
      if (parentFolder) {
        path.unshift(parentFolder);
      } else {
        break;
      }
    }
    path.push(folder);
    return path;
  }
  getFolderByGuid(guid: string): any {
    const stack = [...this.folderData];
    while (stack.length > 0) {
      const current = stack.pop();
      if (current.guid === guid) {
        return current;
      }
      if (current.children) {
        stack.push(...current.children);
      }
    }
    return null;
  }
  getBreadcrumbString(): string {
    return this.folderPath.map(folder => folder.title).join(' > ');
  }

  findFolderPath(folders: any[], childGuid: string): any[] | null {
    for (const folder of folders) {
      if (folder.guid === childGuid) {
        return [folder];
      }
      if (folder.children) {
        for (const child of folder.children) {
          const path = this.findFolderPath(folder.children, childGuid);
          if (path) {
            return [folder, ...path];
          }
        }
      }
    }
    return null;
  }
  isSelected(folder: any): boolean {
    return this.selectedFolderName && this.selectedFolderName['guid'] === folder.guid;
  }

  getFolderElementList(guid: any) {
    this.folderDocumentListGuid = guid;
    this.documentsService
      .getFolderDocumentViewListPagination(
        this.OrganisationID,
        guid,
        this.page,
        (this.searchText = this.searchText),
        this.pageSize,
        this.customFilterString
      )
      .subscribe(
        data => {
          this.totalLength = data.count;
          this.treeApiList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }

  updateSearchText(x: any) {
    this.searchText = x.target.value;
  }
  Search(search_title: string) {
    this.loading = true;
    this.searchText = search_title;
    this.documentsService
      .getFolderDocumentViewListPagination(
        this.OrganisationID,
        this.folderDocumentListGuid,
        this.page,
        (this.searchText = this.searchText),
        this.pageSize,
        this.templateFilter,
        this.monthFilter,
        this.yearFilter,
        this.workspaceTemplateType
      )
      .subscribe(
        data => {
          this.loading = false;
          this.totalLength = data.count;
          this.treeApiList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }
  getInitials(name: string): string {
    const names = name.split(' ');
    let initials = '';
    for (let i = 0; i < names.length; i++) {
      if (names[i]) {
        initials += names[i][0].toUpperCase();
      }
    }
    return initials;
  }

  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    if (this.selectedDocFolderPathsub) {
      this.documentsService.selectedDocFolderPath.next(null);
      this.selectedDocFolderPathsub.unsubscribe();
    }
  }
  goToDocumentdetailspage(DocGuid: any) {
    this.router.navigate(['organisation/documents/attention-required/' + DocGuid]);
  }
  openCreateFolder(folderName?: any) {
    this.documentsService.folderName.next(folderName);
    this.sidenavService
      .open(CreateFolderComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          this.getFolderList();
        }
      });
  }
  openCreateDocument(folderName: any, docInprogressGuid?: string) {
    this.documentsService.folderName.next(folderName);
    this.documentsService.editDocumentGuid.next(docInprogressGuid);
    this.sidenavService
      .open(CreateDocumentComponent)
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          let msg = `Document ${result.result.title} created successfully`;
          this.translate.get(msg).subscribe(res => {
            this.toasterService.showSuccess(res);
            this.documentsService.folderName.next([]);
          });
        }
        this.getFolderList();
      });
  }
  toggleMenu(triggerMenuName: string) {
    this.filtersMenu.forEach((item: any) => {
      item['active'] = item['title'] === triggerMenuName ? true : false;
    });
    this.selectedFilter = triggerMenuName;
    this.filterTrigger.openMenu();
  }
  toggleCheckbox(index: number, userRole: string) {
    if (userRole == 'author') {
      this.peopleList[index].authorSelected = !this.peopleList[index].authorSelected;
    }
  }
}
