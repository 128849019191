<app-spinner *ngIf="loading"></app-spinner>
<div class="training-container">
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
        <h2 class="text-cyan mb-2 m-0 d-flex" style="cursor:default" translate>
          <mat-icon
            _ngcontent-kch-c607=""
            role="img"
            class="mat-icon notranslate cursor-pointer material-icons mat-icon-no-color mr-2"
            style="color: #09305A;"
            aria-hidden="true"
            data-mat-icon-type="font"
            routerLink="/organisation/training"
            >arrow_back</mat-icon
          >
          training_report.title
        </h2>
      </div>
    </div>
    <div class="class2">
      <div class="row">
        <div class="col">
          <div class="my-5 mx-3">
            <div class="row mt-4" style="margin-top:-2em !important">
              <div class="col-6 max-w-500 pr-3">
                <h4 class="font-weight-bold text-black-1" translate>{{ title }}</h4>
                <h4 class="capitalize status h5" translate>Status: {{ status }}</h4>
                <h4 class="capitalize status h5" translate>Due Date: {{ data.end_date }}</h4>
              </div>
            </div>

            <div class="row mt-4" style="margin-top:1em !important">
              <div class="col max-w-500 pr-3">
                <h4 class="font-weight-bold text-black-0 trainees-title" translate>
                  {{ training_report.recurring_renewal }}
                </h4>
                <h4
                  *ngIf="Select_recurring_renewal != null; else no_content"
                  class="text-capitalize status h5"
                  translate
                >
                  {{ Select_recurring_renewal.toLowerCase() }}
                </h4>
                <ng-template #no_content>
                  <h4 class=" status h5" translate>training_report.No recurring renewal set for this document.</h4>
                </ng-template>

                <div class="divider"></div>
              </div>
            </div>

            <div class="row mt-4" style="margin-top:1em !important">
              <div class="col max-w-500 pr-3">
                <h4 class="font-weight-bold text-black-0" *ngIf="description" translate>
                  training_report.description
                </h4>
                <label class="h5" translate *ngIf="description"> {{ description }}</label>
              </div>
            </div>
            <div class="row mt-4" style="margin-top:1em !important">
              <div class="col max-w-500 pr-3">
                <h4 class="font-weight-bold text-black-0 trainees-title" translate>
                  {{ training_report.trainees }} ({{ trainees.length }})
                </h4>
                <div class="block table-responsive" style="height: 16em;">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="font-weight-bold text-black-3" style=" font-size: 14px; width: 30%;">Name</th>
                        <th class="font-weight-bold text-black-3" style="font-size: 14px;">Assigned</th>
                        <th class="font-weight-bold text-black-3" style="font-size: 14px;">Pending</th>
                        <th class="font-weight-bold text-black-3" style="font-size: 14px;">In Progress</th>
                        <th class="font-weight-bold text-black-3" style="font-size: 14px;">Completed</th>
                        <th class="font-weight-bold text-black-3" style="font-size: 14px;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <div *ngIf="checkAccess() && isAtleastOneInviteButtonEnable" class="send-invite">
                        <div
                          style="padding-left: 12px; font-size: 14px; color:#425266;
                      ;"
                        >
                          Send out bulk reminders to all participants who are yet to start training
                        </div>
                        <div>
                          <button
                            class="btn-reinvite"
                            *ngIf="showReInviteAllButton"
                            style="margin-right: 7rem;"
                            (click)="reInviteAllTrainee()"
                          >
                            Re-invite
                          </button>
                          <button
                            type="button"
                            [disabled]="true"
                            style="margin-right:7rem;"
                            class="btn-reminder"
                            *ngIf="!showReInviteAllButton"
                          >
                            Try again in 00:{{ timer < 10 ? '0' + timer : timer }}
                          </button>
                        </div>
                      </div>
                      <ng-container *ngFor="let trainee of trainees">
                        <tr>
                          <td class="data-info">
                            <div class="row align-items-center pl-3">
                              <span class="avatar col-md-auto avatar-bg-{{ trainee.name[0] }}">
                                <span style=" font-size: 12px;">{{ getInitials(trainee.name) }}</span>
                              </span>
                              <div class="col h3" style="font-size: 14px;">{{ trainee.name }}</div>
                            </div>
                          </td>
                          <td class="data-info">
                            <div class="h3">
                              <button
                                type="button"
                                class="btn btn-light"
                                style=" font-size: 14px;"
                                (click)="
                                  viewDocuments(
                                    'assigned',
                                    { description: 'Assigned Date', date: 'assigned_date' },
                                    trainee['email']
                                  )
                                "
                              >
                                {{ trainee.documents.length }}
                              </button>
                            </div>
                          </td>
                          <td class="data-info">
                            <button
                              type="button"
                              class="btn btn-light"
                              style=" font-size: 14px;"
                              (click)="
                                viewDocuments(
                                  'yet_to_start',
                                  { description: 'Due Date', date: 'due_date' },
                                  trainee['email']
                                )
                              "
                            >
                              {{ filterDocumentsByType('yet_to_start', trainee.documents).length }}
                            </button>
                          </td>
                          <td class="data-info">
                            <button
                              type="button"
                              class="btn btn-light"
                              style=" font-size: 14px;"
                              (click)="
                                viewDocuments(
                                  'in_progress',
                                  { description: 'Started Date', date: 'start_date' },
                                  trainee['email']
                                )
                              "
                            >
                              {{ filterDocumentsByType('in_progress', trainee.documents).length }}
                            </button>
                          </td>
                          <td class="data-info">
                            <button
                              type="button"
                              class="btn btn-light"
                              style=" font-size: 14px;"
                              (click)="
                                viewDocuments(
                                  'completed',
                                  { description: 'Completed Date', date: 'completion_date' },
                                  trainee['email']
                                )
                              "
                            >
                              {{ filterDocumentsByType('completed', trainee.documents).length }}
                            </button>
                          </td>
                          <td
                            class="data-info"
                            style="width: 16%;
                        text-align: end; padding-right: 7rem;"
                          >
                            <button
                              *ngIf="trainee.isShowReinvite && checkAccess() && !trainee.isCompletedOrPending"
                              type="button"
                              class="btn-reinvite"
                              (click)="reInviteTrainee(trainee['org_guid'], trainee)"
                            >
                              Re-invite
                            </button>

                            <button
                              type="button"
                              [disabled]="true"
                              class="btn-reminder"
                              *ngIf="!trainee.isShowReinvite && checkAccess() && !trainee.isCompletedOrPending"
                            >
                              Try again in 00:{{ trainee.timer < 10 ? '0' + trainee.timer : trainee.timer }}
                            </button>

                            <img
                              *ngIf="
                                trainee.documents.length == filterDocumentsByType('completed', trainee.documents).length
                              "
                              style=" margin-top: 4px !important ;margin-left: 3.1rem !important;"
                              ngbTooltip="Training Completed"
                              tooltipClass="cb-tooltip"
                              src="/assets/images/core/completeIcon.svg"
                            />
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="divider"></div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col max-w-500 pr-3">
                <h4 class="font-weight-bold text-black-0 trainees-title h4" translate>
                  {{ training_report.documents_list }} ({{ documents.length }})
                </h4>
                <div class="block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="font-weight-bold text-black-3" style="width:70% ; font-size: 14px;">Name</th>
                        <th class="font-weight-bold text-black-3" style=" font-size: 14px;">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let document of documents">
                        <tr>
                          <td class="data-info align-middle">
                            <div class="col h3" style="width:70%;font-size: 14px;">
                              {{
                                document.type == 'cb_document' ? document.document.title : document.external_link.title
                              }}
                            </div>
                          </td>
                          <td class="data-info align-middle">
                            <div
                              class="h3"
                              *ngIf="document?.document?.doc_status !== 'Archived'; else archivedTemplate"
                            >
                              <button
                                type="button"
                                class="btn btn-light"
                                (click)="viewTrainees(document['document']['guid'])"
                                style=" font-size: 14px;"
                              >
                                View Trainees
                              </button>
                            </div>
                            <ng-template #archivedTemplate>
                              <p class="h3" style="width:70%;font-size: 14px;padding-left:15px;">
                                {{ document.document.doc_status }}
                              </p>
                            </ng-template>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
