import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, forkJoin } from 'rxjs';

interface PnpData {
  header: string;
  rows: PnpRow[];
}
interface PnpRow {
  control: string;
  seconds: number;
  policyText: string;
  procedureText: string;
  isPolicyEditable: boolean;
  isProcedureEditable: boolean;
}
@Injectable()
export class WizardService {
  constructor(private http: HttpClient) {}

  public isConfirmedFinishedHippaProgram: any = new BehaviorSubject(false);
  public orgAssesmentArray: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public DocuementGenaratedTitles: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public userOrganizations: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public organisationDetails: ReplaySubject<any[]> = new ReplaySubject<any>(1);
  getAllOrganizations(): Observable<any> {
    return this.http.get(`/api/organizations/account/`, { withCredentials: true });
  }

  getUserOrganizations(): Observable<any> {
    return this.http.get(`/api/organizations/user_organisations/`, { withCredentials: true });
  }

  getAllPrograms(): Observable<any> {
    return this.http.get(`/api/programs/`, { withCredentials: true });
  }

  getOrganizationCommittee(slug: string): Observable<any> {
    return this.http.get(`/api/organizations/${slug}/committees/`, { withCredentials: true });
  }

  getOrganizationCommitteeMembers(org_uuid: string, comm_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/committees/${comm_uuid}/members/`, {
      withCredentials: true
    });
  }

  removeOrganizationCommitteeMembers(org_uuid: string, comm_uuid: string, payload: object[]): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/committees/${comm_uuid}/members/remove/`, payload, {
      withCredentials: true
    });
  }

  addOrganizationCommitteeMembers(org_uuid: string, comm_uuid: string, payload: string[]): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/committees/${comm_uuid}/members/`, payload, {
      withCredentials: true
    });
  }

  organizationUpdate(org_uuid: string, payload: object): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/`, payload, { withCredentials: true });
  }
  organizationBoxUpdate(org_uuid: string, payload: object): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/register/documentstore/`, payload, {
      withCredentials: true
    });
  }
  organizationBoxDetailsUpdate(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/register/documentstore/`, {
      withCredentials: true
    });
  }
  getOrganizationDetails(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/`, { withCredentials: true });
  }

  organizationAddPrograms(org_uuid: string, payload: object): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/addprogram/programs/`, payload, {
      withCredentials: true
    });
  }

  getOrganizationMeetingSchedule(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/meetings/`, { withCredentials: true });
  }

  getOrganizationMeetingScheduleETA(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/meetingschedules/`, {
      withCredentials: true
    });
  }

  runReviewEventNow(review_event_id: string): Observable<any> {
    return this.http.get(`/api/events/${review_event_id}/runrevieweventnow/`, {
      withCredentials: true
    });
  }

  organisationalProgram(org_uuid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/organizationprograms/', {
      withCredentials: true
    });
  }

  testS3Credentials(payload: object): Observable<any> {
    return this.http.post(`/api/awsverification/`, payload, {
      withCredentials: true
    });
  }

  testJiraCredentials(payload: object): Observable<any> {
    return this.http.post(`/api/organizations/test_jira_credentials/`, payload, {
      withCredentials: true
    });
  }

  getJiraIssueTypes(jira_form_paylod: object): Observable<any> {
    console.log('jira_form_paylod', jira_form_paylod);
    const payload = {
      JIRA_API_KEY: jira_form_paylod['JIRA_API_KEY'],
      JIRA_USER_EMAIL: jira_form_paylod['JIRA_USER_EMAIL'],
      JIRA_SERVER_URL: jira_form_paylod['JIRA_SERVER_URL'],
      JIRA_PROJECT_KEY: jira_form_paylod['JIRA_PROJECT_KEY']
    };
    return this.http.post(`/api/organizations/jira_issue_types/`, payload, {
      withCredentials: true
    });
  }

  getDocumentStoresList(): Observable<any> {
    return this.http.get<any>('/api/document_stores/', {
      withCredentials: true
    });
  }
  getOrganizationAssement(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/onboard-assess-questions/`, { withCredentials: true });
  }
  getOrganizationPnpData(org_uuid: string, is_applicable: boolean): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/onboarding/program_unit_lite/?is_applicable=${is_applicable}`,
      { withCredentials: true }
    );
  }
  getOrganizationPnpProcedureData(org_uuid: string): Observable<any> {
    return this.http.get(`/api/pnp-api/organizations/${org_uuid}/procedure/`, { withCredentials: true });
  }
  getOrganizationPnpPolicyData(org_uuid: string): Observable<any> {
    return this.http.get(`/api/pnp-api/organizations/${org_uuid}/policy/`, { withCredentials: true });
  }

  updateOrganizationAssement(org_uuid: string, payload: object): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/pnp-api/onboard-assess-answers/`, payload, {
      withCredentials: true
    });
  }
  organizationAssementAns(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/onboard-assess-answers/`, { withCredentials: true });
  }
  genarateOrganizationDocuments(org_uuid: string, payload: object): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/pnp-api/onboard-generate-documents/`, payload, {
      withCredentials: true
    });
  }
  restoreAndEditPolicy(org_uuid: string, payload: any, id: any): Observable<any> {
    return this.http.put(`/api/pnp-api/organizations/${org_uuid}/policy/${id}/`, payload, {
      withCredentials: true
    });
  }

  restoreAndEditProcedure(org_uuid: string, payload: any, id: any): Observable<any> {
    return this.http.put(`/api/pnp-api/organizations/${org_uuid}/procedure/${id}/`, payload, {
      withCredentials: true
    });
  }

  getSpecificPolicyProcedure(org_uuid: string, prog_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/org_policy_procedure/${prog_guid}/`, {
      withCredentials: true
    });
  }

  getPnpStatus(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/pnp_status/`, { withCredentials: true });
  }

  getCtrlWiseAsmt(org_uuid: string, code: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/onboard-assess-questions/?code=${code}`, {
      withCredentials: true
    });
  }

  saveCtrlWiseAsmt(org_uuid: string, payload: object, is_control_org_assess: boolean): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/pnp-api/onboard-assess-answers/?is_control_org_assess=${is_control_org_assess}`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  regeneratePnp(org_uuid: string, guid: string): Observable<any> {
    return this.http.post(
      `/api/organizations/${org_uuid}/pnp-api/onboard-assess-answers/${guid}/regenerate_policy_procedure_text/`,
      {},
      {
        withCredentials: true
      }
    );
  }

  getPnpStatusForSpecificCtrl(org_uuid: string, code: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/pnp-api/pnp_status/?code=${code}`, { withCredentials: true });
  }
}
